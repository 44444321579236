import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import ServiceCard from "../../components/service-card";
import Faq from "../../components/faq";
import Reviews from "../../components/reviews";
import CtaBanner from "../../components/cta-banner";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SVGZoomDiscovery from "../../components/SVG/zoom-discovery-svg";
import SVGIdentify from "../../components/SVG/identify-svg";
import SVGContentCreated from "../../components/SVG/content-created-svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";

const CopywritingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Info-Panel-Image-Tall" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			socialImg: wpMediaItem(title: { eq: "Social-Media-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			websiteCopyImg: wpMediaItem(title: { eq: "Website-Copy-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blogWritingImg: wpMediaItem(title: { eq: "Blog-Writing-Image-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whoImg: wpMediaItem(title: { eq: "Why-Choose-ARC" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			advertisingImg: wpMediaItem(title: { eq: "Advertising-Copy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whatTypeImg: wpMediaItem(title: { eq: "Translation-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			whatsItImg: wpMediaItem(title: { eq: "Copycoaching-Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			digitalImg: wpMediaItem(title: { eq: "Digital-Content" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blog2Img: wpMediaItem(title: { eq: "Blog-Writing-Image-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const faqs = [
		{
			question:
				"Why should I choose ARC Writing and Translation Services to write my copy?",
			answer:
				"Because having your copy written by a professional writer who knows how to strike the right tone for your business will make your business more successful by creating the right impression with your existing and potential customers, leaving you free to focus on what you do best: running your business",
		},
		{
			question:
				"How do I know ARC Writing and Translation Services will write the best copy for me?",
			answer:
				"Once you have approached ARC Writing and Translation Services to provide you with a quote a meeting will be set up, face-to-face, online – via Zoom or an equivalent platform – or over the phone, to discuss your requirements and get a deeper understanding of your business.",
		},
		{
			question:
				"How will ARC Writing and Translations Services know how my industry works and what copy works best for me business?",
			answer:
				"Once an initial meeting has been set up, ARC Writing and Translation Services will undertake the relevant industry-specific research to identify the correct tone and terminology for your business and find out who your target customers are so that the copy delivered is tailored for the right audience.",
		},
		{
			question: "How much will it cost?",
			answer:
				"All copywriting projects involve different amounts of research and writing time, so it’s not a case of one size fits all. Once you get in touch and outline your needs, a price structure can be determined.",
		},

		{
			question: "How long will it take to deliver the copy?",
			answer:
				"Delivery will depend on the scope of the project and will be discussed upon initial contact. Urgent needs can be accommodated and rush rates applied. The version delivered is never the first draft, so time is needed to write at least three drafts to arrive at the perfect final draft.",
		},

		{
			question:
				"Is it possible to request revisions of the work and, if so, how many?",
			answer:
				"Yes, absolutely. Up to 2 revisions will be provided as standard. Any further changes may be subject to an extra fee.",
		},
	];
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const socialImg = data.socialImg?.localFile.childImageSharp.gatsbyImageData;
	const websiteCopyImg =
		data.websiteCopyImg?.localFile.childImageSharp.gatsbyImageData;

	const blogWritingImg =
		data.blogWritingImg?.localFile.childImageSharp.gatsbyImageData;

	const advertisingImg =
		data.advertisingImg?.localFile.childImageSharp.gatsbyImageData;

	const digitalImg = data.digitalImg?.localFile.childImageSharp.gatsbyImageData;
	const blog2Img = data.blog2Img?.localFile.childImageSharp.gatsbyImageData;
	const whoImg = data.whoImg?.localFile.childImageSharp.gatsbyImageData;
	const whatTypeImg =
		data.whatTypeImg?.localFile.childImageSharp.gatsbyImageData;
	const whatsItImg = data.whatsItImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Property Copywriter London",
				item: {
					url: `${siteUrl}/services/property-copywriter-london`,
					id: `${siteUrl}/services/property-copywriter-london`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Property Copywriter London"
				description="My name is Antoinette, and I am a property copywriter. I specialise in writing for estate agents, facilities management & construction companies."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/property-copywriter-london`,
					title: "Property Copywriter London",
					description:
						"My name is Antoinette, and I am a property copywriter. I specialise in writing for estate agents, facilities management & construction companies.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/copywriting-services-scaled-1.jpg`,
							width: 1621,
							height: 2560,
							alt: "Copywriting services in London",
						},
					],
				}}
			/>
			<ServiceHeroSmall
				secondButtonText="Download my portfolio"
				secondButtonLink="https://arc-writing.rjmdigital.net/wp-content/uploads/2023/01/Antoinette-F-2023-1.pdf"
				secondButton={true}
				imgWidth="15rem"
				image={heroImg}
				imgAlt={data.heroImg.altText}
				title="Property Copywriter"
				subtitle="Providing property copywriting services in London and the South-East"
				text={
					<>
						<p>
							Hi, my name is Antoinette, and I am a property copywriter. I chose
							this niche for my copywriting services based on my extensive
							experience working with estate agents, facilities management and
							construction companies in this industry.
						</p>
					</>
				}
			/>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8} className="text-center">
							{" "}
							<h2 className="mb-5 fs-2 fs-lg-1 ">
								Full range of copywriting
								<br /> services for{" "}
								<span className="underline">estate agents</span>
							</h2>
							<p>
								I can write on-brand, compelling copy for all your marketing and
								sales requirements, from website copywriting services to blogs,
								case studies, brochures, articles and ads.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative py-5 py-lg-10">
				<div
					style={{ zIndex: -999999 }}
					className="w-100 h-100 start-0 top-0 position-absolute bg-light-background"
				></div>
				<Container style={{ zIndex: 1 }} className="position-relative ">
					<Row>
						<Col className="text-center">
							<h2 className="mb-6 underline d-none d-xl-inline-block fs-2 fs-lg-1 ">
								How quality real estate content affects a business's bottom line
							</h2>
						</Col>
					</Row>
					<Row className="g-5">
						<Col lg={{ span: 5, order: "last" }}>
							<div
								style={{ width: "14rem", height: "12rem" }}
								className="bg-background d-lg-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "14rem", height: "12rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "22rem", height: "17rem" }}
								className="bg-background  d-none d-lg-block d-xl-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "22rem", height: "17rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "28rem", height: "23rem" }}
								className="bg-background  d-none d-xl-block position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "28rem", height: "23rem" }}
										image={whoImg}
										alt={data.whoImg.altText}
									/>
								</div>
							</div>
						</Col>
						<Col lg={7}>
							<h2 className="mb-4 d-xl-none fs-2 fs-lg-1 ">
								How quality real estate content affects a business's bottom line
							</h2>
							<p>
								The advantage of hiring a professional property copywriter to
								write your website copy is that good copy can convert leads into
								sales by resonating with your target audience.
							</p>
							<p>
								This is achieved by understanding your ideal client, using a
								consistent tone of voice that reflects your brand and its
								values, and using targeted keywords.
							</p>
							<h3 className="fs-4">Website copy for estate agents</h3>
							<p>
								Copywriting for estate agents requires an in-depth understanding
								of your clients and their pain points. This will be different
								for buyers, sellers, landlords and tenants.
							</p>
							<p>
								By working with me, you can ensure that your copywriting will be
								appropriate for each specific audience you are marketing to.
							</p>
							<Button
								className="cta-btn w-100 w-md-auto me-md-3 px-5 py-2"
								variant="secondary"
								as={Link}
								to="/contact"
							>
								Contact
							</Button>
							<Button
								className="cta-btn w-100 mt-3 mt-md-0  w-md-auto  px-5 py-2"
								variant="outline-secondary"
								target="_blank"
								rel="noreferrer"
								href="https://arc-writing.rjmdigital.net/wp-content/uploads/2023/01/Antoinette-F-2023-1.pdf"
								to="/contact"
							>
								Download my portfolio
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<Container className="text-center pt-5">
				<h2 className="mb-5 underline fs-2 fs-lg-1 ">Copywriting services</h2>
				<Row>
					<ServiceCard
						image={socialImg}
						imgAlt={data.socialImg.altText}
						title="Social media posts & profiles"
						text="Stay ahead of the game among your competitors with posts and articles written for you that generate engagement and cross-platform sharing. Free up more time to focus on other aspects of your business by outsourcing your social media to a professional copywriter."
					/>
					<ServiceCard
						image={websiteCopyImg}
						imgAlt={data.websiteCopyImg.altText}
						title="Website copy"
						text="Engaging copy that generates more sales by using the right words to persuade your customers to buy your products and/or services is essential to grow your business. Transform your website with original, persuasive copy by trusting an expert copywriter to keep your potential customers on-page long enough to secure a sale."
					/>
					<ServiceCard
						image={blogWritingImg}
						imgAlt={data.blogWritingImg.altText}
						title="Blog writing"
						text="Blog posts written that your ideal customers will want to read and that raise your profile online. The subject of your blog posts will be thoroughly researched before it is written to save you time. Share your blogs across all your social media platforms to engage with potential customers and increase sales."
					/>
					<ServiceCard
						image={advertisingImg}
						imgAlt={data.advertisingImg.altText}
						title="Advertising copy"
						text="Does your company use brochures, leaflets and catalogues to market its products and/or services? I can help you by writing copy that best promotes your products and services with the correct wording for your target audience and captivating language that draws the reader in and sells your product."
					/>
					<ServiceCard
						image={digitalImg}
						imgAlt={data.digitalImg.altText}
						title="Digital content"
						text="Whatever your copy requirements are to promote your business online, I can help you by crafting catchy taglines, straplines or by writing video scripts for use on various digital platforms."
					/>
					<ServiceCard
						image={blog2Img}
						imgAlt={data.blog2Img.altText}
						title="Business documents"
						text="Sales letters and/or company reports written that look professional and strike the right tone for your audience. Whether formal for external readers or more informal for an in-house audience, if this sounds like a service that would save you time, then please get in touch for a bespoke quote that meets your needs."
					/>
				</Row>
			</Container>
			<section className=" py-5 py-lg-8">
				<Container>
					<Row className="justify-content-between align-items-center gy-5 gx-7">
						<Col lg={4}>
							<GatsbyImage
								className="w-100"
								image={whatTypeImg}
								alt={data.whatTypeImg.altText}
							/>
						</Col>
						<Col lg={8}>
							{" "}
							<h2 className="mb-4  d-none d-xl-inline-block fs-2 fs-lg-1 ">
								Property sector understanding and marketing{" "}
								<span className="underline">expertise combined</span>
							</h2>
							<h2 className="mb-4 d-xl-none fs-2 fs-lg-1 ">
								Property sector understanding and marketing expertise combined
							</h2>
							<p>
								I have worked with other companies in the property sector and
								understand how to use words to sell. High-quality writing for
								the real estate industry is essential to stand out from the
								competition and improve your bottom line.
							</p>
							<h3 className="fs-4">SEO - Search Engine Optimisation</h3>
							<p>
								SEO may seem like a dark art, but I know what it takes to get
								your property website ranking highly on Google.
							</p>
							<h3 className="fs-4">
								Convert more prospects into happy clients
							</h3>
							<p>
								Having a solid understanding of your client's needs, wants, and
								desires is critical to converting prospects into the happy
								clients you want to have.
							</p>
							<p>
								High-quality copy describing how you solve your prospect's
								problem is also crucial to increase your online conversion
								rates.
							</p>
							<p>
								This is best achieved by working with an experienced property
								copywriter who understands your market.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative py-5 py-lg-8">
				<div
					style={{ zIndex: -999999 }}
					className="w-100 h-100 start-0 top-0 position-absolute bg-light-background"
				></div>
				<Container style={{ zIndex: 1 }} className="position-relative ">
					<Row className="justify-content-center">
						<Col className="text-center" lg={10} xl={8}>
							{" "}
							<h2 className="mb-5 underline d-none d-xl-inline-block fs-2 fs-lg-1 ">
								Estate Agent Testimonial
							</h2>
							<h2 className="mb-4 d-xl-none fs-2 fs-lg-1 ">Estate Agent Testimonial</h2>
							<p>
								"Antoinette has helped us on multiple occasions to create a
								plethora of expertly crafted documents, from landlord guides to
								leaflets. She always thoroughly researches the topic and works
								hard to understand the company ethos and tone. I would have no
								hesitation in recommending her service to others."
							</p>
							<p className="fatface">
								Peter Maskell, Brock Taylor Estate Agents
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" py-5 py-lg-10">
				<Container>
					<Row className="align-items-center g-5">
						<Col lg={{ span: 5, order: "last" }}>
							<div
								style={{ width: "14rem", height: "12rem" }}
								className="bg-background d-lg-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "14rem", height: "12rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "22rem", height: "17rem" }}
								className="bg-background  d-none d-lg-block d-xl-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "22rem", height: "17rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
							<div
								style={{ width: "25rem", height: "20rem" }}
								className="bg-background  d-none d-xl-block position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "28rem", height: "23rem" }}
										image={whatsItImg}
										alt={data.whatsItImg.altText}
									/>
								</div>
							</div>
						</Col>
						<Col lg={7}>
							{" "}
							<h2 className="mb-4 underline d-none d-xl-inline-block fs-2 fs-lg-1 ">
								What’s it like working with me?
							</h2>
							<h2 className="mb-4 d-xl-none fs-2 fs-lg-1 ">
								What’s it like working with me?
							</h2>
							<p>
								As a property and construction copywriter, gaining in-depth
								knowledge of your business is essential.
							</p>
							<p>
								My approach to all new property copywriting clients is first to
								get to know you and see if we are a good fit for each other and
								then find out about what you do and the clients you want to
								attract.
							</p>
							<Button
								className="cta-btn w-100 w-md-auto me-md-3 px-5 py-2"
								variant="secondary"
								as={Link}
								to="/contact"
							>
								Contact
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="position-relative mb-6  text-center">
				<div
					style={{ zIndex: "-3" }}
					className="position-absolute w-100 h-100 bg-light-background  "
				></div>
				<Container className="py-5">
					<Row>
						<Col>
							<h2 className="underline fs-2 fs-lg-1">Typical customer journey</h2>
							<p className="fs-5 mb-5  fw-light">
								You get two free rounds of review before signing off the project
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="px-4" lg={4}>
							<SVGZoomDiscovery className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Zoom discovery</h3>
							<p className="mb-5 ">
								We will have a FREE 30-minute Zoom discovery meeting during
								which we will discuss your requirements.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGIdentify className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">
								Identify your ideal client
							</h3>
							<p className="mb-5 ">
								We will have another Zoom meeting to identify your ideal client,
								establish the correct tone of voice and discuss the keywords for
								your on-page SEO and I will find out all about your business.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGContentCreated className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Content created</h3>
							<p className="mb-5 ">
								I will create content that perfectly suits your needs using
								engaging copy that resonates with your target audience and is
								packed full of YOUR personality.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Reviews description="I'm proud to say that I have several ongoing relationships with satisfied clients, as diverse as a business coach client and an estate agent. You can read my testimonials and Google reviews to find out more." />
			<Faq bottomNoMargin faqs={faqs} />
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default CopywritingPage;
